<template>
  <b-container fluid class="permission-container">
    <!-- Filtros para tabla de usuarios -->
    <b-row class="filters">
      <b-col lg="6" class="my-1">
        <b-form-group
          v-model="sortDirection"
          label="Filtrar por"
          description="Quitar selección en casillas para filtrar por todos los campos"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox-group
            v-model="filterOn"
            :aria-describedby="ariaDescribedby"
            class="mt-1"
          >
            <b-form-checkbox value="first_name">Nombre</b-form-checkbox>
            <b-form-checkbox value="last_name">Apellido</b-form-checkbox>
            <b-form-checkbox value="rut">Rut</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Ordenar"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Ninguno --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Ascendente</option>
              <option :value="true">Descendente</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col lg="6" class="my-1">
        <b-form-group
          label="Filtro"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Ingresar búsqueda..."
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Por página"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <!-- Tabla de usuarios para definir permisos -->
    <b-table
      :items="access_list"
      :fields="user_fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
      hover
      responsive
      @filtered="onFiltered"
    >
      <template #head(selector)>
        <b-form-checkbox
          v-model="select_all"
          @change="selectAllUsers"
        ></b-form-checkbox>
      </template>
      <template #cell(selector)="row">
        <b-form-checkbox
          :value="row.item.user"
          v-model="check_selected"
          :id="`check-user-${row.index}`"
          @change="selectUser"
        ></b-form-checkbox>
      </template>
      <template #cell(role)="row">
        <b-form-select
          v-model="row.item.role"
          :value="row.item.role"
          :options="role_names"
          @change="changeRole(row.item)"
          value-field="id"
          text-field="position_name"
          :state="validateState('role')"
          ><template #first>
            <b-form-select-option :value="null" disabled
              >-- Seleccione un Grupo --</b-form-select-option
            >
          </template></b-form-select
        >
      </template>
      <template v-slot:cell(actions)="row">
        <b-modal
          :id="`access-modal-${row.item.id}`"
          title="Determinar accesos"
          size="xl"
          hide-footer
        >
          <h4>Rol Actual: {{ getRoleName(row.item.role) }}</h4>
          <div class="user-groups">
            <b-card
              :header="`Ver ${$getVisibleNames('mesh.campus', true, 'Sedes')}`"
              header-class="card-header"
              body-class="multi-select-body"
              class="mt-3 multi-select-card w-100 h-100"
              v-if="!objectAccess(row.item.role, 'campuses')"
            >
              <b-form-select
                id="campus-select"
                v-model="row.item.view_campuses"
                :value="row.item.view_campuses"
                :options="campuses"
                value-field="id"
                text-field="name"
                multiple
                size="md"
                :disabled="objectAccess(row.item.role, 'campuses')"
                :select-size="campuses.length > 10 ? 10 : campuses.length"
              ></b-form-select>
            </b-card>
            <b-card
              :header="`Ver ${$getVisibleNames(
                'mesh.faculty',
                true,
                'Facultades'
              )}`"
              header-class="card-header"
              body-class="multi-select-body"
              v-if="!objectAccess(row.item.role, 'faculties')"
              class="mt-3 multi-select-card w-100 h-100"
            >
              <b-form-select
                id="faculty-select"
                v-model="row.item.view_faculties"
                :value="row.item.view_faculties"
                :options="faculties"
                value-field="id"
                text-field="name"
                :disabled="objectAccess(row.item.role, 'faculties')"
                multiple
                size="md"
                :select-size="faculties.length > 10 ? 10 : faculties.length"
              ></b-form-select>
            </b-card>
            <b-card
              :header="`Modificar ${$getVisibleNames(
                'mesh.campus',
                true,
                'Sedes'
              )}`"
              header-class="card-header"
              body-class="multi-select-body"
              class="mt-3 multi-select-card w-100 h-100"
              v-if="!objectAccess(row.item.role, 'campuses')"
            >
              <b-form-select
                id="campus-select"
                v-model="row.item.campuses"
                :value="row.item.campuses"
                :options="campuses"
                value-field="id"
                text-field="name"
                multiple
                size="md"
                :disabled="objectAccess(row.item.role, 'campuses')"
                :select-size="campuses.length > 10 ? 10 : campuses.length"
              ></b-form-select>
            </b-card>
            <b-card
              :header="`Modificar ${$getVisibleNames(
                'mesh.faculty',
                true,
                'Facultades'
              )}`"
              header-class="card-header"
              body-class="multi-select-body"
              v-if="!objectAccess(row.item.role, 'faculties')"
              class="mt-3 multi-select-card w-100 h-100"
            >
              <b-form-select
                id="faculty-select"
                v-model="row.item.faculties"
                :value="row.item.faculties"
                :options="faculties"
                value-field="id"
                text-field="name"
                :disabled="objectAccess(row.item.role, 'faculties')"
                multiple
                size="md"
                :select-size="faculties.length > 10 ? 10 : faculties.length"
              ></b-form-select>
            </b-card>
          </div>
          <div class="user-groups">
            <b-card
              :header="`${$getVisibleNames('mesh.career', true, 'Programas')}`"
              v-if="!objectAccess(row.item.role, 'careers')"
              header-class="card-header"
              body-class="multi-select-body"
              class="mt-2 multi-select-card w-100 h-100"
            >
              <b-form-select
                id="career-select"
                v-model="row.item.view_careers"
                :value="row.item.view_careers"
                :options="careers"
                value-field="id"
                size="md"
                text-field="name"
                multiple
                :disabled="objectAccess(row.item.role, 'careers')"
                :select-size="careers.length > 10 ? 10 : careers.length"
              ></b-form-select>
            </b-card>
            <b-card
              :header="`Modificar ${$getVisibleNames(
                'manual.programa',
                true,
                'Programas'
              )}`"
              v-if="!objectAccess(row.item.role, 'careers')"
              header-class="card-header"
              body-class="multi-select-body"
              class="mt-2 multi-select-card w-100 h-100"
            >
              <b-form-select
                id="career-select"
                v-model="row.item.careers"
                :value="row.item.careers"
                :options="careers"
                value-field="id"
                size="md"
                text-field="name"
                multiple
                :disabled="objectAccess(row.item.role, 'careers')"
                :select-size="careers.length > 10 ? 10 : careers.length"
              ></b-form-select>
            </b-card>
          </div>
          <b-button
            class="btn-save mt-3"
            size="sm"
            @click="saveAccess(row.item)"
            >Cerrar</b-button
          >
        </b-modal>
        <b-button
          :id="`btn-access-${row.item.id}`"
          v-b-modal.access-modal
          :disabled="objectAccess(row.item.role, 'button')"
          @click="$bvModal.show(`access-modal-${row.item.id}`)"
        >
          Asignar
        </b-button>
      </template>
    </b-table>
    <b-button @click="savePermissions"> Guardar Permisos</b-button>
  </b-container>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import { toast } from "@/utils/utils";
//import { validationMixin } from "vuelidate";
//import { required } from "vuelidate/lib/validators";

export default {
  name: "PermissionsTable",
  data() {
    return {
      access_list: [],
      access: [],
      role_names: [],
      has_access: false,
      check_selected: [],
      select_all: false,
      user_fields: [
        { key: "selector", label: "" },
        {
          key: "first_name",
          label: "Nombre",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "last_name",
          label: "Apellido",
          sortable: true,
          sortDirection: "desc",
        },
        { key: "rut", label: "Rut", sortable: true, class: "text-center" },
        { key: "email", label: "E-mail", sortable: true, class: "text-center" },
        {
          key: "role",
          label: "Rol",
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: "Accesos",
          class: "text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Mostrar varios" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
    };
  },
  validations: {
    access_list: {
      role: {
        required: true,
      },
    },
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      campuses: names.CAMPUSES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
    }),
    sortOptions() {
      return this.user_fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.access_list[key];
      return $dirty ? !$error : null;
    },
    changeRole(item) {
      item.careers = [];
      item.faculties = [];
      item.campuses = [];
      item.view_careers = [];
      item.view_faculties = [];
      item.view_campuses = [];
    },
    objectAccess(role_id, name_table) {
      if (this.role_names.length == 0) return true;
      const position = this.role_names.find((role) => role.id == role_id);
      if (position) {
        if (
          (position.position == 4 || position.position == 5) &&
          name_table.includes("careers")
        ) {
          return false;
        }
        if (position.position == 3 && name_table.includes("faculties")) {
          return false;
        }
        if (position.position == 2 && name_table.includes("campuses")) {
          return false;
        }
        if (
          (position.position == 2 ||
            position.position == 3 ||
            position.position == 4 ||
            position.position == 5) &&
          name_table.includes("button")
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchRoleNames() {
      this.$restful.Get(`/teaching/roles/`).then((response) => {
        this.role_names = response;
      });
    },
    fetchAccess() {
      this.access_list = [];
      this.$restful.Get(`/teaching/access/`).then((response) => {
        this.access = response;
        this.users.forEach((x) => {
          if (response) {
            const role = response.find((element) => element.user == x.id);

            if (role) {
              this.access_list.push({
                school: x.school,
                id: role.id,
                first_name: x.first_name,
                last_name: x.last_name,
                email: x.email,
                rut: x.rut,
                user: x.id,
                role: role.role,
                careers: role.careers,
                faculties: role.faculties,
                campuses: role.campuses,
                view_careers: role.view_careers,
                view_faculties: role.view_faculties,
                view_campuses: role.view_campuses,
              });
            } else {
              this.access_list.push({
                school: x.school,
                id: generateUniqueId(),
                first_name: x.first_name,
                last_name: x.last_name,
                email: x.email,
                rut: x.rut,
                user: x.id,
                role: null,
                careers: [],
                faculties: [],
                campuses: [],
                view_careers: [],
                view_faculties: [],
                view_campuses: [],
              });
            }
          }
        });
      });
    },
    saveAccess(element) {
      this.$bvModal.hide(`access-modal-${element.id}`);
    },
    savePermissions() {
      let count = 0;
      this.access_list.forEach((x) => {
        count++;
        const selected = this.check_selected.find((check) => check == x.user);
        if (selected) {
          const find_access = this.access.find(
            (element) => element.user == x.user
          );
          if (find_access) {
            const payload = {
              id: find_access.id,
              user: x.user,
              role: x.role,
              careers: x.careers,
              faculties: x.faculties,
              campuses: x.campuses,
              view_careers: x.view_careers,
              view_faculties: x.view_faculties,
              view_campuses: x.view_campuses,
              school: x.school,
            };
            if (x.role == null)
              return this.$swal({
                title: `Debe seleccionar un Rol para ${x.first_name} ${x.last_name}`,
                type: "warning",
                showCancelButton: true,
              });
            this.$restful
              .Put(`/teaching/access/${find_access.id}/`, payload)
              .then(
                toast(
                  "Acceso actualizado de " +
                    x.first_name +
                    " " +
                    x.last_name +
                    "."
                )
              );
          } else {
            const payload = {
              id: x.id,
              user: x.user,
              role: x.role,
              careers: x.careers,
              faculties: x.faculties,
              campuses: x.campuses,
              view_careers: x.view_careers,
              view_faculties: x.view_faculties,
              view_campuses: x.view_campuses,
              school: x.school,
            };
            if (isNaN(payload.id)) {
              if (x.role == null)
                return this.$swal({
                  title: `Debe seleccionar un Rol para ${x.first_name} ${x.last_name}`,
                  type: "warning",
                  showCancelButton: false,
                });
              this.$restful
                .Post(`/teaching/access/`, payload)
                .then((response) => {
                  this.access.push(response);
                  x.id = response.id;
                  toast("Acceso creado.");
                });
            }
          }
        }
        if (this.access_list.length == count) {
          this.check_selected = [];
        }
      });
      // this.$restful.Post(`/teaching/access/`, this.access_list).then((response) => {
      //   this.$emit("created", response);
      //   toast("Se han guardado los permisos.");
      // });
    },
    selectAllUsers() {
      this.check_selected = [];
      if (this.select_all) {
        this.users.forEach((item) => {
          this.check_selected.push(item.id);
        });
        // for (item in this.users) {
        //   this.check_selected.push(item);
        // }
      }
    },
    selectUser() {
      if (this.check_selected.length == this.users.length) {
        this.select_all = true;
      } else this.select_all = false;
    },
    getRoleName(id) {
      if (id == null) {
        return "Debe seleccionar Rol.";
      } else {
        return this.role_names.find((x) => x.id == id).position_name;
      }
    },
  },
  mounted() {
    this.totalRows = this.users.length;
  },
  created() {
    this.fetchRoleNames();
    this.fetchAccess();
    this.$store.dispatch(names.FETCH_USERS);
    this.$store.dispatch(names.FETCH_CAMPUSES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_CAREERS);
    // TODO: Crear APIView que devuelva todos los datos necesarios de una vez.
  },
};
</script>
<style scoped>
.permission-container {
  padding: 2%;
  background-color: rgb(243, 243, 243);
  border-radius: 10px;
}
.filters {
  margin: 0 2%;
}
.user-groups {
  display: flex;
  width: 100%;
}
.user-card,
.role-card {
  width: 50%;
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
}
option {
  position: relative !important;
}
.multi-select-card {
  width: 50%;
  margin: 0 0.2em;
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
  border-style: none !important;
}
.select-card {
  box-shadow: 1px 2px 4px -2px rgb(92, 92, 92);
}
.card-body {
  padding: 3%;
}
.card-header {
  background-color: var(--primary-color);
}
.multi-select-body {
  padding: 0%;
  margin: 0%;
}
.custom-select,
.option {
  cursor: pointer;
}
.btn-save {
  margin-left: 47%;
}

.info {
  background-color: var(--primary-color);
  border-radius: 10px;
  margin-top: 1.8%;
  padding: 0 2% 2% 2%;
}
.info-container {
  background-color: var(--secondary-font-color);
  border-radius: 10px;
}
.info-title {
  text-align: left;
  padding-top: 1%;
  margin-bottom: 2%;
}
.info-title .b-icon {
  margin-right: 1%;
}
.info .b-icon {
  max-width: 2rem;
  margin-left: 1%;
}
.info p {
  text-align: left;
  margin-top: -0.4%;
  margin-bottom: 0;
}
</style>